<template>
  <div class="vipItem">
    <!-- <swiper ref="vipSwiper" :options="swiperOptions" class="vipSwiper" v-if="vipCardList.length  && rightList.length">
      <swiper-slide v-for="(item,index) in vipCardList" :key="index" class="swiperSlide">
        <img :src="place" class="bgImg" :key="index" />
        <div class="infoBox">
          <div class="tooltip" v-if="item.waLiGameGiveCoin">
            <div>{{`送${item.waLiGameGiveCoin}元游戏币`}}</div>
          </div>
          <div class="content">{{item.desc}}</div>
          <div class="priceBox">
            <span class="price">{{`¥${item.moneyPrePrice / 100}`}}</span>
            <span class="originalPrice">{{`原价¥${item.money / 100}`}}</span>
          </div>
        </div>
      </swiper-slide>
    </swiper> -->
    <div class="newVipBox">
      <div
        class="newVipItem"
        v-for="(item, index) in vipCardList"
        :key="item.selectedImage"
        @click="selectRights(index)"
      >
        <ImgDecypt
          :src="item.selectedImage"
          class="iconUrl"
          :key="item.selectedImage"
        />
        <div class="giftTip" v-if="item.waLiGameGiveCoin">
          {{ `送${item.waLiGameGiveCoin}元游戏币` }}
        </div>
        <div class="vipDesc">{{ item.desc }}</div>
        <div class="newPrice">
          ¥<span>{{ item.moneyPrePrice / 100 }}</span>
        </div>
        <div class="newOriginalPrice">
          ¥<span>{{ `原价¥${item.money / 100}` }}</span>
        </div>
        <div class="activeNewVipItem" v-if="index == activeIndex"></div>
      </div>
      <!-- <div class="newVipItem" v-for="(item,index) in vipCardList" :key="item.selectedImage" @click="selectRights(index)">
        <ImgDecypt :src="item.selectedImage" class="iconUrl" :key="item.selectedImage" />
        <div class="vipCardBorder" v-if="activeIndex == index"></div>
      </div> -->
    </div>

    <div class="newUserTip" v-if="vipCardList[activeIndex].newUserLeftTime">
      {{ vipCardList[activeIndex].newUserActDesc }}
    </div>
    <div
      class="noviceCountdown"
      v-if="vipCardList[activeIndex].newUserLeftTime"
    >
      {{ timeStr }}
    </div>

    <div class="privilegebox_title">
      <div style="width: 66px"></div>
      <div class="title">会员特权</div>
      <router-link tag="div" to="rightsCompared" class="moreBtn"
        >更多特权 <svg-icon iconClass="rightArrow"></svg-icon>
      </router-link>
    </div>
    <van-grid
      class="gridBox"
      :column-num="4"
      gutter="10px"
      center
      :border="false"
      v-if="vipCardList.length && rightList.length"
    >
      <van-grid-item v-for="(item, index) in currentRights" :key="index">
        <div class="gridItem">
          <ImgDecypt :src="item.icon" class="iconUrl" :key="index" />
          <div>{{ item.name }}</div>
        </div>
      </van-grid-item>
    </van-grid>

    <div class="paying_btn" @click="clickPaying">立即充值</div>
    <div class="payTips">支付小贴士</div>
    <div class="payTipsBox">
      <p>1、为防止会员丢失，请绑定手机号或保存账号凭证</p>
      <p>2、跳转后请及时付款，超时支付无法到账，要重新发起</p>
      <p>3、如多次支付失败，请尝试其他支付方式或稍后再试</p>
      <p>
        4、支付成功后一般10分钟内到账，超过30分钟未到账请带详细支付截图联系客服
      </p>
      <p>5、部分安卓手机支付时误报病毒，请选择忽略即可</p>
      <p>
        6、更多问题请点击查看<router-link tag="span" to="problem"
          >「常见问题」</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import { queryPayMode, rehgSubmit } from "@/api/mine/wallet.js";
import "swiper/dist/css/swiper.css";
// import { swiper, swiperSlide } from "vue-awesome-swiper";
import { imgDecypt } from "@/api/app.js";
import { mapGetters } from "vuex";
export default {
  components: {
    // swiper,
    // swiperSlide,
  },
  inject: ["rechgPopShow", "saveRechgInfo"],
  props: {
    vipCardList: {
      type: Array,
      require: true,
      default() {
        return [];
      },
    },
    rightList: {
      type: Array,
      require: true,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isShowRechgBox: false,
      place: require("@/assets/png/place.png"),
      activeIndex: 0,
      selectedItem: {}, // 选中会员卡
      currentRights: [], // 选中会员卡权益
      timeStr: "", // 新手卡倒计时展示
      newUserTime: "", // 新手剩余时间
      swiperOptions: {
        direction: "horizontal", // 水平切换选项
        loop: true, // 循环模式
        slidesPerView: "auto", // 默认一个屏幕显示几张图
        centeredSlides: true, // 每屏，居中显示
        on: {
          slideChangeTransitionEnd: () => {
            this.activeIndex = this.$refs.vipSwiper.swiper
              ? this.$refs.vipSwiper.swiper.realIndex
              : 0;
            this.selectRights(this.activeIndex);
          },
        },
      },
      payModes: [], // 充值方式
      dcInfo: {}, // 代充信息
      dcChannel: "", // 代充域名
    };
  },
  computed: {
    ...mapGetters({
      logicCfg: "logicCfg",
    }),
  },
  created() {
    let newUserArr = this.vipCardList.filter((i) => {
      return i.newUserLeftTime;
    });
    this.newUserTime = newUserArr[0] ? newUserArr[0].newUserLeftTime : 0;
    this.countdown();
    this.selectRights(this.activeIndex);
  },
  mounted() {
    //   会员卡图片解密
    let imgDomain = this.logicCfg.imgDomain;
    let dom = document.querySelectorAll(".vipItem .swiperSlide");
    let datalen = this.vipCardList.length;
    let domlen = dom.length;
    for (let i = 0; i < domlen; i++) {
      let slideIndex = i % datalen;
      let imgDom = dom[i].querySelector("img");
      if (imgDomain.slice(-1) != "/") {
        imgDomain = imgDomain + "/";
      }
      imgDecypt(imgDomain + this.vipCardList[slideIndex].selectedImage).then(
        (url) => {
          if (imgDom) {
            imgDom.src = url;
          }
        }
      );
    }
  },
  methods: {
    async clickPaying() {
      try {
        await this.queryPayMode();
        if (this.payModes.length == 0) {
          this.$toast("当前金额无法充值");
          return;
        }
        this.rechgPopShow(2);
      } catch (e) {
        this.$toast("充值失败");
      }
    },
    selectRights(index) {
      this.activeIndex = index ? index : 0;
      let arr = [...this.vipCardList[this.activeIndex].rights];
      arr.sort((a, b) => {
        return a - b;
      });
      this.currentRights = [];
      arr.forEach((i) => {
        this.rightList.forEach((j) => {
          if (i == j.number) {
            this.currentRights.push(j);
          }
        });
      });
    },
    // 获取充值方式
    async queryPayMode() {
      this.$store.dispatch("app/GET_LOADING", true);
      try {
        let req = {
          payAmount: this.vipCardList[this.activeIndex].moneyPrePrice,
          productType: 0, //0 站群 1 棋牌
        };
        let ret = await this.$Api(queryPayMode, req);
        if (ret && ret.code == 200) {
          let arr = ret.data.payModesInfo ? ret.data.payModesInfo : [];
          this.payModes = arr.filter((i) => {
            return i.payModeId != 0;
          });
          this.dcInfo = ret.data.daiChong;
          this.dcChannel = ret.data.dcH5Domin;

          this.saveRechgInfo({
            payModes: this.payModes,
            dcInfo: this.dcInfo,
            dcH5Domin: this.dcChannel,
            money: req.payAmount,
            title: `购买会员卡`,
            productType: 0,
            rchgUse: 1,
            useId: this.vipCardList[this.activeIndex].id,
          });
        }
        this.$store.dispatch("app/GET_LOADING", false);
      } catch (e) {
        this.$store.dispatch("app/GET_LOADING", false);
      }
    },

    // 提交订单
    async toPay(payType) {
      // let vipGrap = JSON.parse(sessionStorage.getItem('vipGrap'));
      // if (vipGrap.type == 'video') {
      //   optRecord(3, vipGrap.vInfo, "", undefined, 2);
      // } else {
      //   optRecord(3, {}, "", (vipGrap.type || 'user'), 2);
      // }
      let req = {
        payAmount: this.vipCardList[this.activeIndex].moneyPrePrice,
        payMode: payType,
        productType: 0, // 0-站群 1-棋牌
        rchgUse: 1, // 0-金币 1-vip 2-观影券 3-vip升级卡 4-瓦力棋牌 5-裸聊果币
        useId: this.vipCardList[this.activeIndex].id,
      };
      this.$store.dispatch("app/GET_LOADING", true);
      let ret = await this.$Api(rehgSubmit, req);
      // return
      if (ret && ret.code == 200) {
        if (payType != 0) {
          window.location = ret.data.payUrl;
        } else {
          this.$toast("购买成功");
        }
      } else {
        this.$toast(ret.tip || "获取支付方式失败");
      }
      this.$store.dispatch("app/GET_LOADING", false);
    },
    // 代充支付
    dcToPay(dcPayInfo) {
      // let vipGrap = JSON.parse(sessionStorage.getItem('vipGrap'));
      // if (vipGrap.type == 'video') {
      //   optRecord(3, vipGrap.vInfo, "", undefined, 2);
      // } else {
      //   optRecord(3, {}, "", (vipGrap.type || 'user'), 2);
      // }
      let payMoney = this.vipCardList[this.activeIndex].moneyPrePrice / 100;
      let dcData = JSON.parse(JSON.stringify(this.dcInfo)); // 代充信息
      let payInfoModel = JSON.parse(JSON.stringify(dcPayInfo)); // 代充支付类型
      let token = localStorage.getItem("Authorization");
      let productInfo = {
        id: this.vipCardList[this.activeIndex].id,
        type: 1,
      };
      this.$store.dispatch("app/GET_LOADING", true);
      //设置默认值
      if (dcData.limit == 0) {
        dcData.limit = 500;
      }
      if (
        payInfoModel.payType.includes(2) &&
        payInfoModel.payType.includes(3)
      ) {
        if (payMoney > dcData.limit) {
          payInfoModel.payType = [3];
        } else {
          payInfoModel.payType = [2];
        }
      }
      if (payInfoModel.payType.length >= 3) {
        if (payMoney > dcData.limit) {
          payInfoModel.payType = [1, 3];
        } else {
          payInfoModel.payType = [1, 2];
        }
      }
      dcData.traders[0].payInfos = [payInfoModel];
      dcData.productInfo = productInfo;
      dcData.chargeMoney = payMoney;
      dcData.channel = this.dcChannel;
      let models = JSON.stringify(dcData);
      let payUrl = `${this.dcChannel}/?data=${this.encodeBase64(
        models
      )}&token=${token}`;
      this.$store.dispatch("app/GET_LOADING", false);
      window.location = payUrl;
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
    // 倒计时
    countdown() {
      if (this.newUserTime <= 0) return;
      // 相差的毫秒数
      const msec = this.newUserTime;
      // 计算时分秒数
      let day = parseInt(msec / 60 / 60 / 24);
      let hr = parseInt((msec / 60 / 60) % 24);
      let min = parseInt((msec / 60) % 60);
      let sec = parseInt(msec % 60);
      // 个位数前补零
      hr = hr > 9 ? hr : "0" + hr;
      min = min > 9 ? min : "0" + min;
      sec = sec > 9 ? sec : "0" + sec;
      this.timeStr = `剩余${
        day > 0 ? day + "天" : ""
      }${hr}小时${min}分钟${sec}秒恢复原价`;
      // 控制台打印
      // console.log(`${day > 0 ? day + '天' : ''} ${hr}小时 ${min}分钟 ${sec}秒`)
      // 一秒后递归
      setTimeout(() => {
        this.newUserTime--;
        this.countdown();
      }, 1000);
    },
    // 支付类型
    handlePayType(payType, isSvg) {
      let nameStr, svgStr;
      switch (payType) {
        case 1:
          nameStr = "支付宝";
          svgStr = "icon_pay_a1";
          break;
        case 2:
          nameStr = "微信";
          svgStr = "icon_pay_w1";
          break;
        case 3:
          nameStr = "银行卡";
          svgStr = "pay_kjyl";
          break;
        case 4:
          nameStr = "USDT";
          svgStr = "USDT";
          break;
        case 6:
          nameStr = "快捷银联";
          svgStr = "pay_kjyl";
          break;
        case 101:
          nameStr = "支付宝";
          svgStr = "icon_pay_a1";
          break;
        case 102:
          nameStr = "微信";
          svgStr = "icon_pay_w1";
          break;
        case 103:
          nameStr = "银行卡";
          svgStr = "pay_kjyl";
          break;
        case 104:
          nameStr = "信用卡";
          svgStr = "pay_kjyl";
          break;
        case 105:
          nameStr = "花呗";
          svgStr = "icon_pay_a1";
          break;
        case 106:
          nameStr = "云闪付";
          svgStr = "pay_kjyl";
          break;
        case 107:
          nameStr = "QQ钱包";
          svgStr = "pay_kjyl";
          break;
        case 108:
          nameStr = "京东支付";
          svgStr = "pay_kjyl";
          break;
        default:
          nameStr = "支付宝";
          svgStr = "icon_pay_a1";
          break;
      }
      return isSvg ? svgStr : nameStr;
    },
  },
  beforeDestroy() {
    this.newUserTime = 0;
  },
};
</script>

<style lang="scss" scoped>
.vipItem {
  height: calc(100vh - 111px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .swiperSlide {
    width: 239px;
    height: 132px;
    margin: 0 10px;
    // border-radius: 9px;
    position: relative;
    color: $white1;

    .bgImg {
      width: 100%;
      height: 100%;
      background: #dcdee0;
      border-radius: 9px;
    }

    .infoBox {
      // width: 100%;
      // height: 100%;
      // position: absolute;
      // top: 0;
      // left: 0;
      // padding: 10px 15px;
      // box-sizing: border-box;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;

      .content {
        width: 100%;
        height: 50px;
        font-size: 10px;
        line-height: 14px;
        white-space: pre-wrap;
        overflow: hidden;
        position: absolute;
        left: 16px;
        top: 54px;
      }

      .priceBox {
        position: absolute;
        bottom: 10px;
        left: 16px;

        .originalPrice {
          color: rgba(255, 255, 255, 0.7);
          font-size: 10px;
          text-decoration: line-through;
          font-weight: 400;
        }

        .price {
          font-weight: 600;
          font-size: 16px;
          margin-right: 5px;
        }
      }

      // .cardTitle {
      //     // position: absolute;
      //     // top: 10px;
      //     // left: 15px;
      //     display: flex;
      //     align-items: flex-end;
      //     font-size: 16px;
      //     font-weight: 600;

      .tooltip {
        position: absolute;
        top: 10px;
        right: 22px;
        color: $vermillion;
        width: 90px;
        height: 26px;
        background: url("../../../../assets/png/tooltip.png");
        background-size: 100% 100%;
        text-align: center;
        line-height: 18px;
        font-size: 12px;
        font-weight: normal;
        // margin-left: 8px;
      }

      // }
    }
  }

  .privilegebox_title {
    font-size: 16px;
    // height: 22px;
    margin-top: 24px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-weight: 600;

    .moreBtn {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 66px;
    }
  }
  .paying_btn {
    position: fixed;
    left: 50%;
    margin-left: -150px;
    bottom: 150px;
    width: 300px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
    background-image: linear-gradient(
      90deg,
      #fc4478 0%,
      #ff6538 99%,
      #f54404 100%
    );
    box-shadow: 0 6px 8px 0 rgba(248, 44, 44, 0.4);
    border-radius: 20px;
  }
  .rechg_box {
    .title {
      font-family: PingFangSC-Semibold;
      font-size: 20px;
      color: #000000;
      letter-spacing: 1.23px;
      text-align: center;
      font-weight: 600;
    }
  }
  .gridBox {
    // color: $black;
    margin-top: 25px;
    .gridItem {
      text-align: center;
      font-size: 10px;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;

      .iconUrl {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin: 0 auto 7px;

        /deep/ .vanImage {
          background: none;
        }
      }

      div {
        height: 23px;
      }
    }

    /deep/ .van-grid-item__content {
      padding: 0;
    }
  }

  .rechargeTitle {
    font-size: 16px;
    margin: 22px 0 7.5px;
    padding: 0 10px;
    font-weight: 600;
  }

  .rechargeList {
    // padding-bottom: 7.5px;
    height: 50px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(139, 139, 139, 0.3);

    .payLogo {
      width: 34px;
      height: 34px;
    }

    .type {
      flex: 2;
      margin-left: 20px;
      font-size: 14px;
    }
  }

  .dcList {
    height: 50px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(139, 139, 139, 0.3);

    .payLogo {
      width: 34px;
      height: 34px;
    }

    .titleBox {
      flex: 2;
      margin-left: 20px;
      height: 34px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .typeBox {
        font-size: 10px;
        display: flex;
        align-items: center;

        .recommend {
          margin-left: 8px;
          width: 25px;
          height: 12.5px;
        }
      }
    }
  }

  .payTips {
    font-size: 14px;
    padding: 0 10px;
    margin: 8px 0 10px;
  }

  .payTipsBox {
    font-size: 10px;
    margin: 0 10px;
    width: 310px;
    color: $greyishBrown;
    padding-bottom: 200px;

    p {
      margin-bottom: 5px;
    }

    span {
      color: $vermillion;
    }
  }

  .newUserTip {
    margin: 15px auto 10px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    width: 80%;
    text-align: center;
    background: red;
    border-radius: 30px;
    color: $white1;
    font-weight: 600;
  }

  .noviceCountdown {
    font-size: 12px;
    text-align: center;
    margin-bottom: 10px;
  }

  .newVipBox {
    padding: 0 10px;
    height: 267px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 11px;
    grid-column-gap: 7px;
    .newVipItem {
      width: 112px;
      height: 128px;
      border-radius: 12px;

      overflow: hidden;
      position: relative;
      /deep/ .vanImage {
        background: #00000000 !important;
      }
      // .vipCardBorder {
      //   width: 112px;
      //   height: 128px;
      //   background: url("../../../../assets/png/vipCardBorder.png");
      //   background-size: 100% 100%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      // }
    }
    .activeNewVipItem {
      position: absolute;
      width: 107px;
      height: 123px;
      z-index: 999;
      border-radius: 12px;
      top: 0;
      left: 0;
      // background: #ff1d1d;
      border: 2.5px solid #ff1d1d;
    }
    .giftTip {
      position: absolute;
      font-size: 7px;
      top: 6.6px;
      right: 9.4px;
      color: #fff;
    }
    .vipDesc {
      font-size: 9px;
      color: #fff;
      // width: 95px;
      height: 45.7px;
      line-height: 12px;
      white-space: pre-wrap;
      overflow: hidden;
      position: absolute;
      left: 5.4px;
      // top: 45.7px;
      top: 50px;
    }
    .newPrice {
      position: absolute;
      left: 6px;
      bottom: 5px;
      font-size: 13px;
      color: #fff;
      span {
        font-size: 15px;
      }
    }
    .newOriginalPrice {
      position: absolute;
      right: 10px;
      bottom: 6px;
      color: #fff;
      font-size: 9px;
      text-decoration: line-through;
    }
  }
}
</style>

import axios from "@/utils/request";

// 查询金币配置
export function queryVipCards(data) {
    return axios.post(`/vip/cardlist`, data);
}

// 查询会员权益
export function queryVipRights(data) {
    return axios.post(`/vip/rights`, data);
}
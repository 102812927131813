<template>
    <div class="vip">
        <div class="tabs">
            <div class="title" v-for="(item,index) in tabs" :key="index" :class="{'isActive': activeTab === index}"
                @click="changeTab(index)">
                {{item}}</div>
        </div>
        <van-notice-bar class="noticeBar" color="#000" background="#edd3a4" left-icon="volume-o"
            :text="marquee" speed="40" v-if="marquee" />

        <swiper ref="swiperBox" :options="swiperBoxOptions" class="swiperBox">
            <swiper-slide class="vipItem">
                <VipItem :vipCardList='vipCardList' :rightList="rightList" v-if="vipCardList.length && rightList.length"/>
                <!-- <VipItem :vipList="vipList" :dcInfo="dcInfo"></VipItem> -->
            </swiper-slide>
            <swiper-slide class="privilegeItem">
                <PrivilegeItem :privilegeCard='privilegeCard' v-if="privilegeCard.length"/>
                <!-- <PrivilegeItem :privilegeList="privilegeList" :dcInfo="dcInfo"></PrivilegeItem> -->
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
    import { queryVipCards, queryVipRights } from '@/api/mine/vip.js'
    import "swiper/dist/css/swiper.css";
    import {
        swiper,
        swiperSlide
    } from 'vue-awesome-swiper';
    import VipItem from './VipItem.vue';
    import PrivilegeItem from './PrivilegeItem.vue';
    export default {
        components: {
            swiper,
            swiperSlide,
            VipItem,
            PrivilegeItem,
        },
        data() {
            return {
                tabs: ["会员卡", "特权卡"],
                // 轮播配置
                activeTab: 0,
                swiperBoxOptions: {
                    observer: true,
                    observeParents: true,
                    on: {
                        slideChangeTransitionEnd: () => {
                            this.activeTab = this.$refs.swiperBox.swiper.activeIndex;
                        },
                    },
                },
                vipCardList: [],
                privilegeCard: [],
                rightList: [],
                marquee: '', // 跑马灯
            }
        },
        created() {
            this.queryVipCards();
        },
        methods: {
            // 切换tab
            changeTab(index) {
                this.$refs.swiperBox.swiper.slideTo(index)
            },
            // 查询会员卡
            async queryVipCards() {
              this.$store.dispatch("app/GET_LOADING", true);
                let ret = await this.$Api(queryVipCards);
                if (ret && ret.code == 200) {
                    this.vipCardList = ret.data.list.filter(i => {
                        return i.cardType == 1;
                    })
                    this.privilegeCard = ret.data.list.filter(x => {
                        return x.cardType == 4
                    })
                    this.marquee = ret.data.marquee;
                    this.queryVipRights();
                }
            },
            async queryVipRights() {
              try {
                let ret = await this.$Api(queryVipRights);
                this.$store.dispatch("app/GET_LOADING", false);
                if (ret && ret.code == 200) {
                  this.rightList = ret.data.list ? ret.data.list : [];
                }
              } catch (e) {
                this.$store.dispatch("app/GET_LOADING", false);
              }
            },
        }
    }
</script>

<style lang='scss' scoped>
    .vip {
        height: calc(100vh - 44px);

        /deep/ .van-tab--active {
            background: none;
            font-size: 13px;
            color: $vermillion;
        }

        .tabs {
            width: 148px;
            display: flex;
            justify-content: space-between;
            color: $greyishBrown;
            // margin: 3px auto 0;
            margin-top: 4px;
            padding: 0 114px;
            font-size: 13px;
            border-bottom: 1px solid rgba(169, 169, 170, 0.23);
        }

        .isActive {
            color: $vermillion;
            text-align: center;
            // margin-bottom: 4px;
        }

        .isActive::after {
            content: "";
            height: 2px;
            display: block;
            margin-top: 2px;
            background: $vermillion;
            // margin: 0 auto 0;
            // border-bottom: 0.04rem solid $vermillion;
        }

        .noticeBar {
            height: 24px;
            font-size: 12px;
            margin: 7px 0 12px;

            /deep/ .van-notice-bar__left-icon {
                color: $vermillion;
            }
        }
    }
</style>

<template>
    <div class="privilegeItem">
        <div class="privilegeCard" v-for="(item, index) in privilegeCard" :key="index" @click="showPayModes(item)">
            <ImgDecypt :src="item.defaultImage" class="bgImg" :key="index" />
            <div class="infoBox">
                <div class="descBox">
                    <div class="title">{{item.title}}</div>
                    <div class="content">{{item.desc}}</div>
                </div>

                <div class="priceBox">
                    <div class="price">¥ <span>{{item.moneyPrePrice / 100}}</span></div>
                    <div class="originalPrice">{{`原价${item.money / 100}`}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        queryPayMode
    } from '@/api/mine/wallet.js'
    export default {
        props: {
            privilegeCard: {
                type: Array,
                require: true,
                default () {
                    return [];
                },
            },
        },
        data() {
            return {
                isRchg: true,
            }
        },
        created() {
            // this.$store.dispatch("app/GET_APPCONFIG", JSON.stringify(ret.data));
        },
        methods: {
            async showPayModes(item) {
                let req = {
                    payAmount: item.moneyPrePrice,
                    productType: 0, //0 站群 1 棋牌
                }
                this.$store.dispatch("app/GET_LOADING", true);
                let ret = await this.$Api(queryPayMode, req);
                this.$store.dispatch("app/GET_LOADING", false);
                if (ret && ret.code == 200) {
                    let payModes = [];
                    if (ret.data.payModesInfo) {
                        payModes = ret.data.payModesInfo.filter(i => {
                            return i.payModeId != 0
                        });
                    }

                    let info = {
                        dcInfo: ret.data.daiChong,
                        dcChannel: ret.data.dcH5Domin,
                        payModes: payModes,
                        payAmount: item.moneyPrePrice,
                        useId: item.id,
                        status: true,
                    }
                    this.$store.dispatch("user/setPrivilRchgInfo", info);
                }
            }
        },
    }
</script>

<style lang='scss' scoped>
    .privilegeItem {
        height: calc(100vh - 111px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .privilegeCard {
            width: 328px;
            height: 110px;
            margin: 0 auto 15px;
            position: relative;

            .bgImg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .infoBox {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 10px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                color: $white1;

                .descBox {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    font-size: 12px;

                    .title {
                        font-size: 16px;
                        font-weight: 600;
                    }
                }

                .priceBox {
                    display: flex;
                    flex-direction: column;
                    justify-content: end;

                    .price {
                        font-size: 12px;
                        text-align: center;
                        margin-bottom: 10px;

                        span {
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }

                    .originalPrice {
                        color: rgba(255, 255, 255, 0.7);
                        font-size: 12px;
                        text-decoration: line-through;
                    }
                }
            }
        }
    }
</style>
